
import { Component, Vue } from "vue-property-decorator";
import _ from "underscore";
import * as rhovanion from "@/api/rhovanion";
import TheDocument from "../../components/inventory/TheDocuments.vue";
import Notify from "@/utils/notifications";

interface Query {
  serialNumber: string | null;
  location: string | null;
  brand: string | null;
  category: string | null;
  assigned: string | null;
}

@Component({
  components: {
    TheDocument,
  },
})
export default class ContributorsDetails extends Vue {
  readonly rolReport = "creator";

  // Colors
  readonly cSuccess = "#198754";
  readonly cDanger = "#dc3545";
  readonly cDefault = "#000000";

  document = false;

  loading = true;
  batch = false;
  addAction = false;
  dialogVisible = false;
  dialogTitle = "";
  devicesSelected: number[] = [];
  assignable = true;
  unassignable = false;

  assetDocuments: Array<rhovanion.DataAsset> = [];

  availableDevices = {} as rhovanion.Payload<rhovanion.Device>;
  devices = { count: 0, rows: [] } as rhovanion.Payload<
    rhovanion.ContributorDevices
  >;
  contributor = {} as rhovanion.SingleContributor;
  history = [{} as rhovanion.HistoryContributor];
  reports = { count: 0, rows: [] } as rhovanion.Payload<
    rhovanion.ReportContributor
  >;

  //Asignar dispositivos
  loadingDevices = false;
  brands: rhovanion.DeviceBrand[] = [];
  currentPage = 1;
  devicesSelectedAssign: number[] = [];
  readonly defaultParams = {
    query: {
      serialNumber: null,
      brand: null,
    },
    assigned: 0,
    limit: 10,
    offset: 0,
    sort: null,
  };
  params = _.clone(this.defaultParams);
  //

  async getContributorDetails() {
    const devices = await rhovanion.findContributorDevices(
      this.$route.params.id
    );
    this.devices = devices;

    const contributor = await rhovanion.findOneContributor(
      this.$route.params.id
    );
    this.contributor = contributor;

    const history = await rhovanion.findContributorHistory(
      this.$route.params.id
    );
    this.history = history;

    const reports = await rhovanion.findContributorReports(
      this.$route.params.id,
      this.rolReport
    );
    this.reports = reports;

    const responsivas = await rhovanion.findContributorResposives(
      this.$route.params.id
    );
    this.assetDocuments = responsivas;
  }

  openDialog(add: boolean) {
    this.addAction = add;
    this.dialogVisible = true;
    this.dialogTitle = add ? "Asignar dispositivos" : "Desasignar dispositivos";
    if (add) {
      this.loadingDevices = true;
      rhovanion
        .findDeviceBrand({
          limit: 0,
          offset: 0,
          sort: "+name",
        })
        .then(res => {
          this.brands = res;
        });
      rhovanion
        .findDevice({
          ...this.params.query,
          limit: this.params.limit,
          offset: this.params.offset,
          assigned: 0,
        })
        .then(res => {
          this.availableDevices = res;
        })
        .finally(() => {
          this.loadingDevices = false;
        });
    }
  }

  async getDevices(page = this.currentPage) {
    this.loadingDevices = true;
    this.currentPage = page;
    this.params.offset = (this.currentPage - 1) * this.params.limit;
    rhovanion
      .findDevice({
        ...this.params.query,
        limit: this.params.limit,
        offset: this.params.offset,
        assigned: 0,
      })
      .then(res => {
        this.availableDevices = res;
      })
      .finally(() => {
        this.loadingDevices = false;
      });
  }

  getFilteredDevices() {
    this.loadingDevices = true;
    this.params.limit = this.defaultParams.limit;
    this.params.offset = this.defaultParams.offset;
    rhovanion
      .findDevice({
        ...this.params.query,
        limit: this.params.limit,
        offset: this.params.offset,
        assigned: 0,
        sort: this.params.sort,
      })
      .then(res => {
        this.availableDevices = res;
      })
      .finally(() => {
        this.loadingDevices = false;
      });
  }

  selectionChangeAssign(val: rhovanion.ContributorDevices[]) {
    this.devicesSelectedAssign = _.pluck(val, "id");
  }

  selectionChange(val: rhovanion.ContributorDevices[]) {
    this.devicesSelected = _.pluck(val, "id");
    this.unassignable = false;
    this.assignable = false;
    if (val.every(device => device.assigned === true)) {
      this.unassignable = true;
      this.assignable = false;
    }

    if (this.devicesSelected.length === 0) {
      this.unassignable = false;
      this.assignable = true;
    }
  }

  closeDialog(value: boolean) {
    this.dialogVisible = value;
  }

  closeDocument(value: boolean) {
    this.document = value;
  }

  assignDevice() {
    if (this.devicesSelectedAssign.length > 0) {
      rhovanion
        .multipleAssign(this.$route.params.id, this.devicesSelectedAssign)
        .then(() => {
          this.getContributorDetails();
          Notify.successful(`Operación realizada exitosamente.`);
        })
        .catch((error: any) => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.closeDialog(false);
          this.devicesSelectedAssign = [];
        });
    }
  }

  unassignDevice() {
    rhovanion
      .multipleUnassign(this.$route.params.id, this.devicesSelected)
      .then(() => {
        this.getContributorDetails();
        Notify.successful(`Operación realizada exitosamente.`);
      })
      .catch((error: any) => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.closeDialog(false);
        this.devicesSelected = [];
      });
  }

  assignColor(text: string): string {
    let hexColor = "";
    switch (text) {
      case "Dispositivo asignado":
        hexColor = this.cSuccess;
        break;
      case "Dispositivo desasignado":
        hexColor = this.cDanger;
        break;
      default:
        hexColor = this.cDefault;
        break;
    }

    return hexColor;
  }

  formatDate(fecha: string) {
    return new Date(fecha).toLocaleDateString();
  }

  async mounted() {
    await this.getContributorDetails();
    this.loading = false;
  }

  created() {
    const index = "7.2";
    const title = "Detalles";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Inventario",
        to: {
          name: "inventory-devices",
        },
      },
      {
        text: "Colaboradores",
        to: {
          name: "inventory-contributors",
        },
      },
      {
        text: "Detalles",
      },
    ]);
  }

  get fullName() {
    return this.contributor.name + " " + this.contributor.lastname;
  }

  get isDeleted(): boolean {
    return this.contributor["deleted-at"] === null;
  }
}
