
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataAsset, uploadResponsive } from "@/api/rhovanion";

interface AssetUpload {
  name: string;
  percentage: number;
  raw: RawUpload;
  size: number;
  status: string;
  uid: number;
}

interface RawUpload extends File {
  uid: number;
  lastModifiedDate: any;
  size: number;
  type: string;
}

@Component
export default class TheDocument extends Vue {
  @Prop() show!: boolean;
  @Prop() documents!: DataAsset[];
  @Prop({ default: false }) upload?: boolean;
  @Prop({ default: undefined }) userData?: { id: string; name: string };

  loading = false;
  assetsList: Array<AssetUpload> = [];
  isSubmitting = false;
  readonly maxAssetSize = 10 * 1024 * 1024; //10MB

  openDocument(index: number) {
    window.open(this.documents[index].url, "_blank");
  }

  uploadResponsive() {
    const formData = this.buildFormData(
      this.assetsList,
      this.userData?.name as string
    );
    for (const [name, image] of formData.entries()) {
      this.isSubmitting = true;
      const formDataImage = new FormData();
      formDataImage.append(name, image);

      uploadResponsive(this.userData?.id as string, formDataImage)
        .then(() => {
          this.$emit("newResponsive");
          this.$message.success("Responsiva subida correctamente");
        })
        .catch(() => {
          this.$message.error("El usuario no tiene dispositivos asignados");
        })
        .finally(() => {
          this.assetsList = [];
          this.isSubmitting = false;
          this.$refs.upload.clearFiles();
          this.$emit("closeDialog", false);
        });
    }
  }

  buildFormData(files: Array<AssetUpload>, userName: string) {
    const formData = new FormData();
    files.map(({ raw }) => {
      formData.append("file", this.renameFile(raw, userName));
    });
    return formData;
  }

  renameFile(file: File, userName: string): File {
    const newName = `Resguardo - ${userName}`;
    return new File([file], newName, { type: file.type });
  }

  onRemove(file: AssetUpload) {
    this.assetsList = this.assetsList.filter(({ uid }) => uid !== file.uid);
  }

  onUpload(file: AssetUpload, fileList: AssetUpload[]) {
    if (this.beforeUpload(file)) {
      this.assetsList.push(file);
    } else {
      this.$message.error("El tamaño del archivo no puede ser mayor de 10MB");
      fileList.pop();
    }
  }

  beforeUpload(file: AssetUpload) {
    return file.size < this.maxAssetSize;
  }

  closeDialog() {
    this.$emit("closeDialog", false);
  }

  get showDialog() {
    return this.show;
  }

  get titleModal() {
    return this.upload ? "RESPONSIVAS" : "DOCUMENTOS";
  }
}
